<template>
  <b-row class="match-height">
    <b-col lg="12">
      <b-card title="Confirm Purchase Request By Purchasing">
        <b-row>
          <b-col cols="12">
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            <b-dropdown
              text="Download Documents"
              v-if="documents.length > 0"
              v-model="showDropdown"
              class="float-rigth"
            >
              <!-- Your dropdown content here -->
              <b-dropdown-item
                v-for="document in documents"
                :key="document.purchase_request_document_number"
                @click="downloadAttachment(document)"
                >{{
                  document.purchase_request_document_number
                }}</b-dropdown-item
              >
            </b-dropdown>
            <b-button
              type="button"
              variant="primary"
              class="mr-1 float-rigth"
              @click="print()"
            >
              <feather-icon icon="PrinterIcon" size="16" class="align-middle" />
              Print
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-form
          @submit.prevent="submitConfirmPurchaseRequest"
>
            <b-form-group
              label="Number"
              label-for="purchase_request_number"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_request_number"
                v-model="purchaseRequestNumber"
                placeholder="Project"
                readonly
              />
            </b-form-group>
            <b-form-group
              label=" Type"
              label-for="purchase_request_type"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_request_type"
                v-model="purchaseRequestType"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Class *"
              label-for="purchase_request_class"
              label-cols-md="12"
            >
              <b-form-input id="chosenClass" v-model="chosenClass" readonly />
            </b-form-group>
            <b-form-group
              label="Project"
              label-for="purchase_request_project"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_request_project"
                v-model="purchaseRequestProject"
                placeholder="Project"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Cost Center"
              label-for="purchase_request_cost_center"
              label-cols-md="12"
            >
              <b-form-input id="chosenBranch" v-model="chosenBranch" readonly />
            </b-form-group>
            <b-form-group
              label="Deliver To"
              label-for="purchase_request_deliver_to"
              label-cols-md="12"
            >
              <b-form-input id="deliverTo" v-model="purchaseRequestDeliverTo" readonly />
            </b-form-group>
            <b-form-group
              label="Need For"
              label-for="purchase_request_need_for"
              label-cols-md="12"
            >
              <b-form-input id="purchase_request_need_for" v-model="purchaseRequestNeedFor" readonly />
            </b-form-group>
            <b-form-group
              label="Category"
              label-for="purchase_request_category"
              label-cols-md="12"
            >
              <b-form-input id="purchase_request_category" v-model="purchaseRequestCategory" readonly />
            </b-form-group>
            <b-form-group
              label="Notes"
              label-for="purchase_request_notes"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_request_notes"
                v-model="purchaseRequestNotes"
                placeholder="Notes"
                readonly
              />
            </b-form-group>
            <b-form-group
  label="Asset Category"
  label-for="purchase_request_asset_category"
  label-cols-md="12"
  v-if="purchaseRequestAssetCategory != ''"
>
  <b-form-input
    id="purchase_request_asset_category"
    v-model="purchaseRequestAssetCategory"
    placeholder="Notes"
  v-if="purchaseRequestAssetCategory != ''"
    readonly
  />
</b-form-group>
            <b-row>
              <b-col cols="4" class="text-center font-weight-bold">
                Item name
              </b-col>
              <b-col cols="2" class="text-center font-weight-bold">
                Unit name
              </b-col>
              <b-col cols="2" class="text-center font-weight-bold">
                Item QTY Requested
              </b-col>
              <b-col cols="2" class="text-center font-weight-bold">
                Item QTY Accepted
              </b-col>
              <b-col cols="2" class="text-center font-weight-bold">
                Avg Req / Monthly
              </b-col>
            </b-row>
            <p />
            <b-row v-for="chosen in chosens" :key="chosen.value">
              <br />
              <b-col cols="4 text-center">
                <b-list-group>
                  <b-list-group-item href="#">
                    {{ chosen.label }}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col cols="2 text-center">
                <b-list-group>
                  <b-list-group-item href="#">
                    {{ capitalizeWords(chosen.unit_name) }}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col cols="2 text-center">
                <b-form-input
                  v-model="chosen.qty_requested"
                  type="number"
                  placeholder="Qty Requested"
                  :max="chosen.qty_requested"
                  readonly
                />
              </b-col>
              <b-col cols="2 text-center">
                <b-form-input
                  v-model="chosen.qty_accepted"
                  type="number"
                  placeholder="Qty Accepted"
                  :max="chosen.qty_accepted"
                  readonly
                />
              </b-col>
              <b-col cols="2 text-center">
                <b-form-input
                  v-model="chosen.avg"
                  type="number"
                  placeholder="Avg Req"
                  :max="chosen.avg"
                  readonly
                />
              </b-col>
              <b-col cols="12">
                <br />
              </b-col>
            </b-row>
            <b-form-group>
              <label for="no_invoice">Status :</label>
              <vue-horizontal-timeline :items="statusItems" />
            </b-form-group>
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="submitButtonDisabled"
          >
            Confirm
          </b-button>
            </b-form>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import Ripple from "vue-ripple-directive"

import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import { ref } from "@vue/composition-api"

const VersionCheck = ""
const statusItems = []
const items = []
const chosenItem = ref()
const chosens = []
const documents = []
const purchaseRequestType = ""
const purchaseRequestNumber = ""
const purchaseRequestIsConfirmed = ""
const purchaseRequestIsConfirmedByFACoalManager = ""
const purchaseRequestClass = ""
const purchaseRequestProject = ""
const purchaseRequestCostCenter = ""
const purchaseRequestQtyRequested = 0
const purchaseRequestStatus = ""
const purchaseRequestNotes = ""
const purchaseRequestDeliverTo = ""
const purchaseRequestNeedFor = ""
const purchaseRequestCategory = ""
const purchaseRequestAssetCategory = ""
const chosenBranch = ""
const branches = []
const chosenClass = ""
const classes = ["Urgent", "Normal"]
const submitButtonDisabled = false
export default {
  components: {
    BDropdownItem,
    BDropdown,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    VueHorizontalTimeline,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      purchaseRequestAssetCategory,
      submitButtonDisabled,
      purchaseRequestDeliverTo,
      purchaseRequestNeedFor,
      purchaseRequestCategory,
      documents,
      purchaseRequestIsConfirmedByFACoalManager,
      purchaseRequestType,
      purchaseRequestIsConfirmed,
      chosenBranch,
      branches,
      chosenClass,
      classes,
      statusItems,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      purchaseRequestNumber,
      purchaseRequestClass,
      purchaseRequestProject,
      purchaseRequestCostCenter,
      purchaseRequestQtyRequested,
      purchaseRequestStatus,
      purchaseRequestNotes,
    }
  },
  mounted() {
    this.chosens = []
    this.getBranches()
    this.getDetails()
    this.getDocuments()
  },
  methods: {
    downloadAttachment(document) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const FileName = document.purchase_request_document_file_name
      const ObjectName = `${document.purchase_request_document_number}${FileName}`
      const body = {
        ObjectName,
        FileName,
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_ASSET}`,
          body,
          { headers },
        )
        .then(response => {
          if (response.data.Status === 1) {
            window.open(response.data.Payload, "_blank")
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Download Attachment success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    getDetails() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const id = localStorage.getItem("purchaseRequestDetailsId")
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS_DETAILS}${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            axios
              .get(
                `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS_SINGLE}${id}`,
                { headers },
              )
              .then(response2 => {
                if (response2.data.success === true) {
                  this.chosenClass = response2.data.data.purchase_request_class
                  this.purchaseRequestProject = response2.data.data.purchase_request_project
                  const branchCity = response2.data.data.cost_center ? response2.data.data.cost_center.branch_city : "-"
                  const branchCode = response2.data.data.cost_center ? response2.data.data.cost_center.branch_code : "-"
                  this.chosenBranch = `${branchCity} (${branchCode})`
                  this.purchaseRequestNotes = response2.data.data.purchase_request_notes
                  this.purchaseRequestType = response2.data.data.purchase_request_is_center === true
                      ? "Headoffice"
                      : "Cabang"
                  this.purchaseRequestIsConfirmedByFACoalManager = response2.data.data
                      .purchase_request_is_confirmed_by_fa_coal_manager !== null
                        ? true
                        : false
                  this.purchaseRequestNumber = response2.data.data.purchase_request_number
                  this.purchaseRequestIsConfirmed = response2.data.data.purchase_request_is_confirmed !== null
                      ? true
                      : false
                  this.purchaseRequestNeedFor = response2.data.data.purchase_request_need_for
                  this.purchaseRequestCategory = response2.data.data.purchase_request_category
                  this.purchaseRequestDeliverTo = response2.data.data.purchase_request_deliver_to
                  this.purchaseRequestAssetCategory = response2.data.data.purchase_request_asset_category !== null && typeof response2.data.data.purchase_request_asset_category !== 'undefined' ? response2.data.data.purchase_request_asset_category : ''
                  let obj1 = null
                  let obj2 = null
                  let obj3 = null
                  let obj4 = null
                  let obj5 = null
                  let obj6 = null
                  let obj7 = null
                  let obj8 = null
                  let obj9 = null
                  if (response2.data.data.purchase_request_is_center === true) {
                    obj1 = {
                      title: "Created",
                      content: `Purchase Request Has been Created By ${
                        response2.data.data.creator.user_fullname
                      } at ${this.dateSimple(
                        response2.data.data.purchase_request_created_time,
                        "YYYY-MM-DD",
                      )}`,
                      stepCssClass: "has-step-green",
                      boxCssClass: "has-color-red",
                    }
                    obj2 = {
                      title: "Laboratory Manager",
                      content:
                        response2.data.data
                          .purchase_request_is_confirmed_by_laboratory_manager !== null
                            ? `Purchase Request Has been Accepted by ${
                                response2.data.data.laboratory_manager
                                  .user_fullname
                              } at ${this.dateSimple(
                                response2.data.data
                                  .purchase_request_is_confirmed_by_laboratory_manager_time,
                                "YYYY-MM-DD",
                              )}`
                            : `Purchase Request Has not been Accepted By Laboratory Manager`,
                      stepCssClass:
                        response2.data.data
                          .purchase_request_is_confirmed_by_laboratory_manager !== null
                            ? "has-step-green"
                            : "has-step-red",
                    }
                    obj3 = {
                      title: "Regional Manager",
                      content:
                        response2.data.data
                          .purchase_request_is_confirmed_by_regional_manager !== null
                            ? `Purchase Request Has been Confirmed By Regional Manager at ${this.dateSimple(
                                response2.data.data
                                  .purchase_request_is_confirmed_by_regional_manager_time,
                                "YYYY-MM-DD",
                              )}`
                            : `Purchase Request Has not been Confirmed by Regional Manager`,
                      stepCssClass:
                        response2.data.data
                          .purchase_request_is_confirmed_by_regional_manager !== null
                            ? "has-step-green"
                            : "has-step-red",
                    }
                    obj4 = {
                      title: "Division Head",
                      content:
                        response2.data.data
                          .purchase_request_is_confirmed_by_division_head !== null
                            ? `Purchase Request Has been Confirmed By Division Head at ${this.dateSimple(
                                response2.data.data
                                  .purchase_request_is_confirmed_by_division_head_time,
                                "YYYY-MM-DD",
                              )}`
                            : `Purchase Request Has not been Confirmed by Division Head`,
                      stepCssClass:
                        response2.data.data
                          .purchase_request_is_confirmed_by_division_head !== null
                            ? "has-step-green"
                            : "has-step-red",
                    }
                    obj5 = {
                      title: "FA Coal Manager",
                      content:
                        response2.data.data
                          .purchase_request_is_confirmed_by_fa_coal_manager !== null
                            ? `Purchase Request Has been Confirmed By FA Coal Manager at ${this.dateSimple(
                                response2.data.data
                                  .purchase_request_is_confirmed_by_fa_coal_manager_time,
                                "YYYY-MM-DD",
                              )}`
                            : `Purchase Request Has not been Confirmed by FA Coal Manager`,
                      stepCssClass:
                        response2.data.data
                          .purchase_request_is_confirmed_by_fa_coal_manager !== null
                            ? "has-step-green"
                            : "has-step-red",
                    }
                    obj6 = {
                      title: "Operation Director",
                      content:
                        response2.data.data
                          .purchase_request_is_confirmed_by_operation_director !== null
                            ? `Purchase Request Has been Confirmed By Operation Director at ${this.dateSimple(
                                response2.data.data
                                  .purchase_request_is_confirmed_by_operation_director_time,
                                "YYYY-MM-DD",
                              )}`
                            : `Purchase Request Has not been Confirmed by Operation Director`,
                      stepCssClass:
                        response2.data.data
                          .purchase_request_is_confirmed_by_operation_director !== null
                            ? "has-step-green"
                            : "has-step-red",
                    }
                    obj7 = {
                      title: "Vice President",
                      content:
                        response2.data.data
                          .purchase_request_is_confirmed_by_vice_president !== null
                            ? `Purchase Request Has been Confirmed By Vice President at ${this.dateSimple(
                                response2.data.data
                                  .purchase_request_is_confirmed_by_vice_president_time,
                                "YYYY-MM-DD",
                              )}`
                            : `Purchase Request Has not been Confirmed by Vice President`,
                      stepCssClass:
                        response2.data.data
                          .purchase_request_is_confirmed_by_vice_president !== null
                            ? "has-step-green"
                            : "has-step-red",
                    }
                    obj8 = {
                      title: "President Director",
                      content:
                        response2.data.data
                          .purchase_request_is_confirmed_by_president_director !== null
                            ? `Purchase Request Has been Confirmed By President Director at ${this.dateSimple(
                                response2.data.data
                                  .purchase_request_is_confirmed_by_president_director_time,
                                "YYYY-MM-DD",
                              )}`
                            : `Purchase Request Has not been Confirmed by President Director`,
                      stepCssClass:
                        response2.data.data
                          .purchase_request_is_confirmed_by_president_director !== null
                            ? "has-step-green"
                            : "has-step-red",
                    }
                    obj9 = {
                      title: "Procurement Pusat",
                      content:
                        response2.data.data
                          .purchase_request_is_confirmed_by_procurement_pusat !== null
                            ? `Purchase Request Has been Confirmed By Procurement Pusat at ${this.dateSimple(
                                response2.data.data
                                  .purchase_request_is_confirmed_by_procurement_pusat_time,
                                "YYYY-MM-DD",
                              )}`
                            : `Purchase Request Has not been Confirmed by Procurement Pusat`,
                      stepCssClass:
                        response2.data.data
                          .purchase_request_is_confirmed_by_procurement_pusat !== null
                            ? "has-step-green"
                            : "has-step-red",
                    }
                    this.statusItems = []
                    this.statusItems.push(obj1)
                    this.statusItems.push(obj2)
                    this.statusItems.push(obj3)
                    this.statusItems.push(obj4)
                    this.statusItems.push(obj5)
                    this.statusItems.push(obj6)
                    this.statusItems.push(obj7)
                    this.statusItems.push(obj8)
                    this.statusItems.push(obj9)
                  } else {
                    if (response2.data.data.purchase_request_category === 'Assets' || response2.data.data.purchase_request_category === 'FGS') {
                    obj1 = {
                      title: "Created",
                      content: `Purchase Request Has been Created By ${
                        response2.data.data.creator.user_fullname
                      } at ${this.dateSimple(
                        response2.data.data.purchase_request_created_time,
                        "YYYY-MM-DD",
                      )}`,
                      stepCssClass: "has-step-green",
                      boxCssClass: "has-color-red",
                    }
                    obj2 = {
                      title: "Purchasing Cabang",
                      content:
                        response2.data.data.purchase_request_is_confirmed_by_purchasing_cabang !== null
                          ? `Purchase Request Has been Accepted by ${
                              response2.data.data.purchasing_cabang.user_fullname
                            } at ${this.dateSimple(
                              response2.data.data
                                .purchase_request_is_confirmed_by_purchasing_cabang_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Purchase Request Has not been Accepted By Purchasing Cabang`,
                      stepCssClass:
                        response2.data.data.purchase_request_is_confirmed_by_purchasing_cabang !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    obj3 = {
                      title: "Laboratory Manager",
                      content:
                        response2.data.data.purchase_request_is_confirmed_by_laboratory_manager !== null
                          ? `Purchase Request Has been Accepted by ${
                              response2.data.data.laboratory_manager.user_fullname
                            } at ${this.dateSimple(
                              response2.data.data
                                .purchase_request_is_confirmed_by_laboratory_manager_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Purchase Request Has not been Accepted By Laboratory Manager`,
                      stepCssClass:
                        response2.data.data.purchase_request_is_confirmed_by_laboratory_manager !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    obj4 = {
                      title: "Regional Manager",
                      content:
                        response2.data.data.purchase_request_is_confirmed_by_regional_manager !== null
                          ? `Purchase Request Has been Accepted by ${
                              response2.data.data.regional_manager.user_fullname
                            } at ${this.dateSimple(
                              response2.data.data
                                .purchase_request_is_confirmed_by_regional_manager_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Purchase Request Has not been Accepted By Regional Manager`,
                      stepCssClass:
                        response2.data.data.purchase_request_is_confirmed_by_regional_manager !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    obj5 = {
                      title: "Division Head",
                      content:
                        response2.data.data.purchase_request_is_confirmed_by_division_head !== null
                          ? `Purchase Request Has been Accepted by ${
                              response2.data.data.division_head.user_fullname
                            } at ${this.dateSimple(
                              response2.data.data
                                .purchase_request_is_confirmed_by_division_head_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Purchase Request Has not been Accepted By Division Head`,
                      stepCssClass:
                        response2.data.data.purchase_request_is_confirmed_by_division_head !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    obj6 = {
                      title: "FA Coal Manager",
                      content:
                        response2.data.data.purchase_request_is_confirmed_by_fa_coal_manager !== null
                          ? `Purchase Request Has been Accepted by ${
                              response2.data.data.fa_coal_manager.user_fullname
                            } at ${this.dateSimple(
                              response2.data.data
                                .purchase_request_is_confirmed_by_fa_coal_manager_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Purchase Request Has not been Accepted By FA Coal Manager`,
                      stepCssClass:
                        response2.data.data.purchase_request_is_confirmed_by_fa_coal_manager !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    this.statusItems = []
                    this.statusItems.push(obj1)
                    this.statusItems.push(obj2)
                    this.statusItems.push(obj3)
                    this.statusItems.push(obj4)
                    this.statusItems.push(obj5)
                    this.statusItems.push(obj6)
                  } else if (response2.data.data.purchase_request_category === 'Supplies') {
                    obj1 = {
                      title: "Created",
                      content: `Purchase Request Has been Created By ${
                        response2.data.data.creator.user_fullname
                      } at ${this.dateSimple(
                        response2.data.data.purchase_request_created_time,
                        "YYYY-MM-DD",
                      )}`,
                      stepCssClass: "has-step-green",
                      boxCssClass: "has-color-red",
                    }
                    obj2 = {
                      title: "Purchasing Cabang",
                      content:
                        response2.data.data.purchase_request_is_confirmed_by_purchasing_cabang !== null
                          ? `Purchase Request Has been Accepted by ${
                              response2.data.data.purchasing_cabang.user_fullname
                            } at ${this.dateSimple(
                              response2.data.data
                                .purchase_request_is_confirmed_by_purchasing_cabang_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Purchase Request Has not been Accepted By Purchasing Cabang`,
                      stepCssClass:
                        response2.data.data.purchase_request_is_confirmed_by_purchasing_cabang !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    obj3 = {
                      title: "Laboratory Manager",
                      content:
                        response2.data.data.purchase_request_is_confirmed_by_laboratory_manager !== null
                          ? `Purchase Request Has been Accepted by ${
                              response2.data.data.laboratory_manager.user_fullname
                            } at ${this.dateSimple(
                              response2.data.data
                                .purchase_request_is_confirmed_by_laboratory_manager_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Purchase Request Has not been Accepted By Laboratory Manager`,
                      stepCssClass:
                        response2.data.data.purchase_request_is_confirmed_by_laboratory_manager !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    this.statusItems = []
                    this.statusItems.push(obj1)
                    this.statusItems.push(obj2)
                    this.statusItems.push(obj3)
                  } else {
                    obj1 = {
                      title: "Created",
                      content: `Purchase Request Has been Created By ${
                        response2.data.data.creator.user_fullname
                      } at ${this.dateSimple(
                        response2.data.data.purchase_request_created_time,
                        "YYYY-MM-DD",
                      )}`,
                      stepCssClass: "has-step-green",
                      boxCssClass: "has-color-red",
                    }
                    obj2 = {
                      title: "Laboratory Manager",
                      content:
                        response2.data.data.purchase_request_is_confirmed_by_laboratory_manager !== null
                          ? `Purchase Request Has been Accepted by ${
                              response2.data.data.laboratory_manager.user_fullname
                            } at ${this.dateSimple(
                              response2.data.data
                                .purchase_request_is_confirmed_by_laboratory_manager_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Purchase Request Has not been Accepted By Laboratory Manager`,
                      stepCssClass:
                        response2.data.data.purchase_request_is_confirmed_by_laboratory_manager !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    this.statusItems = []
                    this.statusItems.push(obj1)
                    this.statusItems.push(obj2)
                  }
                  }
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Get Data Failed",
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  })
                }
              })
            const itemsJoin = []
            response.data.data.map(elem => {
              itemsJoin.push({
                label: `${elem.item.item_name}`,
                unit_name: `${elem.item.unit ? elem.item.unit.unit_name : '-'}`,
                value: elem.purchase_request_detail_id,
                qty_requested: elem.purchase_request_detail_item_qty_requested,
                qty_accepted: elem.purchase_request_detail_item_qty_accepted,
                avg: elem.purchase_request_detail_item_avg,
              })
            })
            this.chosens = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Branches Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Data Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    getDocuments() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const id = this.$cookies.get("purchaseRequestDetailsId")
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS_DOCUMENTS}/${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = []
            response.data.data.map(elem => {
              itemsJoin.push(elem)
            })
            this.documents = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Documents Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Documents Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    getBranches() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = []
            response.data.data.map(elem => {
              itemsJoin.push({
                label: `${elem.branch_name} (${elem.branch_code})`,
                value: elem.branch_id,
              })
            })
            this.branches = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Branches Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Branches Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    submitNewPurchaseRequest() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.chosens.map(elem => {
        this.purchaseRequestQtyRequested += parseInt(elem.qty)
      })
      const body = {
        purchase_request_class: this.chosenClass,
        purchase_request_project: this.purchaseRequestProject,
        purchase_request_cost_center: this.chosenBranch.value,
        purchase_request_notes: this.purchaseRequestNotes,
        material_request_id: this.purchaseRequestQtyRequested,
        purchase_request_items: JSON.stringify(this.chosens),
        time: moment(),
      }
      axios
        .post(
          "http://localhost:5000/transactions/purchase_requests/create",
          body,
          { headers },
        )
        .then(response => {
          this.submitButtonDisabled = false
          if (response.data.success === true) {
            this.chosens = []
            this.purchaseRequestQtyRequested = 0
            this.purchaseRequestCostCenter = ""
            this.purchaseRequestProject = ""
            this.purchaseRequestClass = ""
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Purchase Request Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
            this.$router.replace({
              name: "apps-procurement-purchase-request-list",
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Purchase Request Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Purchase Request Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    getOptionLabel(option) {
      return (option && option.label) || ""
    },
    applyItem(val) {
      console.log(val.data)
    },
    getOptionKey(option) {
      return (option && option.value) || ""
    },
    inputSearch(search) {
      console.log(search)
    },
    dateFormat(value, format = "MM/DD/YYYY") {
      return moment(String(value)).format(format)
    },
    previous() {
      this.$router.push({ name: "apps-procurement-purchase-request-list" })
    },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
    submitConfirmPurchaseRequest() {
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
        const id = localStorage.getItem('purchaseRequestDetailsId')
        // const userData = this.$cookies.get('userData')
        // if (userData.user_signature !== null) {
        const body = {
        time: moment(),
        }
        const url = `${process.env.VUE_APP_API_URL}transactions/purchase_requests/confirm/center`
    axios
      .post(`${url}/purchasing/${id}`, body, { headers })
      .then(async response => {
        if (response.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Purchase Request Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.replace({ name: 'apps-procurement-purchase-request-list' })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      // } else {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'You have to upload your Signature First!',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger',
      //     },
      //   })
      // }
    },
    print() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const id = this.$cookies.get("purchaseRequestDetailsId")
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_PURCHASE_REQUEST}${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const url = `${process.env.VUE_APP_API_URL}temp/${response.data.data}`
            window.open(url, "_blank")
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Download Data failed",
                icon: "CheckIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    capitalizeWords(str) {
    // Split the string into words using spaces as the separator
    const words = str.split(' ')
    // Capitalize the first letter of each word and join them back together
    const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    })
    // Join the capitalized words into a single string with spaces
    return capitalizedWords.join(' ')
    },
  },
}
</script>
